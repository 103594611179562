@import "../node_modules/@picocss/pico/scss/pico";

body, html {
  margin: 0;
}

.logotype {
  font-weight: bold;
  font-size: 1.5rem;
  color: $white;
  white-space: nowrap;
}

.auto-width {
  width: auto;
}

.button-bar {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.error {
  color: #ee0000;
  font-weight: bold;
}

.label {
  padding: 4px 8px;
  text-transform: uppercase;
  background-color: $primary-700;
  color: $white;
  border-radius: 4px;
  font-weight: bold;
  font-size: 0.8rem;

  &.inactive {
    border-color: $primary-700;
    border-width: 1px;
    border-style: solid;
    background-color: transparent;
  }
}
